import { template as template_ad168f92566e4661b7b6daaf7bbde3d1 } from "@ember/template-compiler";
const WelcomeHeader = template_ad168f92566e4661b7b6daaf7bbde3d1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
