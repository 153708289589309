import { template as template_38eaeff3268d4c6583fe48408bb18050 } from "@ember/template-compiler";
const FKLabel = template_38eaeff3268d4c6583fe48408bb18050(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
