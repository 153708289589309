import { template as template_a43b82b5b193473383eb18af4a6d02bc } from "@ember/template-compiler";
const SidebarSectionMessage = template_a43b82b5b193473383eb18af4a6d02bc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
